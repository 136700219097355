.dock-door-popup-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dock-popup-bottom-section {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5px 0px;
}

.dock-door-wrapper {
  width: 100%;
  height: 100%;
  background-color: var(--modal-bg);
  position: fixed;
  z-index: 1008;
  top: 0;
  left: 0;
  overflow: hidden;
  cursor: pointer;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dock-door-wrapper-container {
  overflow-y: scroll;
  width: 90%;
  height: 95vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--modal-blue-bg);
  cursor: pointer;
  border-radius: 30px;
}

.dock-door-details-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 10px;
}

.modal-action-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.modal-action-button {
  width: 15px;
  height: 15px;
}

.modal-action-button-no-height {
  width: 15px;
}

.modal-action-button-wrapper:hover {
  background-color: var(--white);
}

.modal-action-button-wrapper {
  width: 50px;
  height: 50px;
  margin-right: 20px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50px;
  transition: 0.2s all;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dock-door-details-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.dock-door-details-body {
  display: flex;
  align-items: flex-start;
}

.dock-door-detail-left-section {
  display: flex;
  flex: 0.7;
  flex-direction: column;
  width: 100%;
  margin-left: 50px;
}

.detail-section-container:first-child {
  margin: 0;
}
.detail-section-container:last-child {
  margin-bottom: 50px;
}

.left-detail-title-section {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: 30px;
  margin-top: 10px;
}

.left-detail-title-section h3 {
  font-weight: 500;
  font-size: 24px;
}

/* .left-detail-data-section {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
} */

.detail-section-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  background-color: var(--white);
  border-radius: 16px;
  margin-top: 20px;
}

.dock-spinner-wrapper {
  width: 100%;
  height: 20px;
  margin: 20px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.dock-spinner-wrapper h4 {
  margin: 0px 10px;
}

.column-section {
  flex-direction: column;
  align-items: flex-start;
}

.details-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.details-wrapper-scroller {
  height: 250px !important;
  overflow-y: scroll !important;
}

.additional-data-section-title h3 {
  margin: 20px 0px 0px 30px;
  color: var(--tertiary-white);
  text-transform: uppercase;
  font-weight: 500;
  line-height: 20px;
  font-size: 20px;
}

.extra-bottom-margin {
  margin-bottom: 20px;
}

.status-card-status-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 30px;
  gap: 15px;
}

.status-card {
  width: 170px;
  height: 40px;
  background-color: var(--liteBlue);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin: 0px 20px 0px 0px;
}

.status-card img {
  width: 24px;
  height: 24px;
  padding-right: 10px;
}

.status-card p {
  font-size: 14px;
  font-weight: 400;
  color: var(--primaryBlue);
}

.status-card-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  /* width: 170px; */
  height: 41px;
}

.status-card-text p {
  height: 21px;
  font-weight: 400;
  font-size: 14px;
  color: var(--grey);
  margin-top: -5px;
}

.valid-text-tag {
  color: var(--available) !important;
  font-weight: 500;
  font-size: 16px;
}
.invalid-text-tag {
  color: var(--error-red) !important;
  font-weight: 500;
  font-size: 16px;
}
.manual-text-tag {
  color: var(--warning) !important;
  font-weight: 500;
  font-size: 16px;
}
.auto-text-tag {
  color: var(--available) !important;
  font-weight: 500;
  font-size: 16px;
}

.pallets-table {
  border-collapse: collapse;
  width: 100%;
  margin: 10px 30px;
  border-radius: 10px;
  border-style: hidden;
  box-shadow: 0 0 0 1px var(--table-border);
}

.pallets-table-header-section,
.pallets-table-data {
  height: 64px;
  border-bottom: 1px solid var(--table-border);
}

.pallets-table-header,
.pallets-table-row-data {
  text-align: left;
  padding-top: 10px;
  padding-left: 20px;
}

.detail-section {
  width: 200px;
  max-width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 30px;
}

.detail-section h4 {
  color: var(--tertiary-white);
  font-weight: 400;
  margin-bottom: 0;
}

.detail-section p {
  color: var(--primaryBlue);
  font-weight: 500;
  font-size: 16px;
  margin-top: 0;
}
/* Fix styling of Ant Progress */
.detail-section h4:has(+ div) {
  margin: 0;
}

.dock-door-detail-right-section {
  width: 100%;
  display: flex;
  flex: 0.3;
  padding: 30px;
  margin: 0 50px;
  background-color: var(--white);
  border-radius: 16px;
}

.dock-door-popup {
  width: 100%;
  background-color: var(--white);
  border-radius: 30px 30px 4px 4px;
  border: 5px solid var(--lite-border);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
  z-index: 1008 !important;
}

.dock-popup-top-section {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.75vw;
  background-color: var(--liteBlue);
  border-radius: 25px 25px 0 0;
  border-bottom: 5px solid var(--lite-border);
}

.dock-popup-small {
  padding: 0px 5px;
  border-radius: 10px;
}

.dock-popup {
  height: 5vh;
  margin: 2px 0px;
  font-size: 0.6vw;
  color: var(--white);
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dock-popup h4 {
  text-align: center;
}

.dock-popup-big {
  width: 85%;
  border-radius: 10px;
  margin: 2px 0px;
  display: flex;
  flex-direction: row;
}

.dock-popup-big-complex-right,
.dock-popup-big-complex-left {
  flex: 1;
  margin: 0px 0.6px;
  border-radius: 10px;
}

.ant-progress-bg {
  height: 20px !important;
}

.dock-door-brief-loader {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
  border-radius: 16px;
}

.dock-door-brief-loader h4 {
  margin-right: 10px;
}

.spinner {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: radial-gradient(farthest-side, var(--primary-color) 94%, #0000)
      top/4px 4px no-repeat,
    conic-gradient(#0000 30%, var(--primary-color));
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 4px), #000 0);
  animation: s3 1s infinite linear;
}

@keyframes s3 {
  100% {
    transform: rotate(1turn);
  }
}
