.navbar-container {
    width: 100%;
    min-width: fit-content; 
    background-color:var(--liteBlue);
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    position: relative;
    /* overflow-y: hidden; */
}

.navbar-container .left-section {
    display: flex;
    flex-direction: row;
}

.navbar-container .left-section .logo{
    height: 50px;
    margin-left: 30px;
}

.time-machine-selected, .heat-map-selected {
    background-color: var(--faded-primary-blue);
    border: 1px solid var(--primary-color);
}

.logo a img {
    height: 60px;
    margin-top: -5px;
}

.navbar-container .left-section .location{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 30px;
}

.navbar-container .right-section {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    margin-right: 30px;
}

.prevent-scroll {
    overflow: hidden;
    width: 100%;
}

.heatmap-icon {
    width: 17px;
    height: 17px;
}

@media only screen and (max-width: 780px) {
    .button-container {
        height: 10px;
        font-size: 10px !important;
    }
    .navbar-container{
        width:fit-content;
    }
}

@media only screen and (max-width: 430px) {
    .navbar-container .right-section {
        display: none;
    }

    .navbar-container {
        justify-content: center;
        align-items: center;
    }
}

@media only screen and (max-width: 330px) {
    .logo a img{
        margin-top: 2px;
        width: 180px;
        height: 50px;
    }

    .button-container {
        padding: 10px 10px !important;
    }
}