.network-wrapper {
    position:fixed;
    bottom: 15px;
    z-index: 1111;
    right: 0px;
}
.network-container {
    width: 35px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    background-color: white;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}
.network-text {
    font-size: 14px;
}
.network-status {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    border-radius: 50px;
}
.network-connected {
    background-color:#34eb46;
}
.network-not-connected {
    background-color: #fc4c4c;
}