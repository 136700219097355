.location-container {
    padding: .1px 25px;
    background-color: var(--white);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    font-size: 14px;
    margin: 0px 7px;
}

.location-container .location-icon {
    margin-right: 10px;
}

.loading {
    cursor: progress;
    pointer-events: none;
}