.lgv-check-list-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 20px;
  top: 10px;
  z-index: 1111;
}

.check-list-btn {
  width: 80px;
  height: 40px;
  background-color: white;
  border-radius: 25px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  color: var(--primary-blue);
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

/* adjustment for larger screen */
@media only screen and (min-width: 2500px) {
  .lgv-check-list-container{
    top: 200px;
    right:450px
  }
}

@media only screen and (min-width: 3500px) {
  .lgv-check-list-container{
    top: 400px;
    right:950px
  }
}

@media only screen and (min-width: 4500px) {
  .lgv-check-list-container{
    top: 650px;
    right:1550px
  }
}