.zoom-indicator-wrapper {
  position: absolute;
  left: 60px;
  top: 10px;
  z-index: 1111;
}

.zoom-indicator-container {
  border-radius: 10px;
  padding: 8px;
  background-color: #f1f3f5;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.zoom-indicator-container-left-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.info-icon-left-section {
  margin-right: 5px;
  margin-bottom: -3px;
}

.info-text-left-section {
  font-size: 16px;
  font-weight: 400;
  color: var(--secondary-text);
}

.zoom-indicator-container-right-section {
}

.zoom-text-right-section {
  font-weight: 600;
  font-size: 16px;
  color: var(--secondary-text);
}

/* Larger screens Adjustments */

@media only screen and (min-width: 2500px)  {
  .zoom-indicator-wrapper {
   left: 500px;
   top: 190px;
  }
}

@media only screen and (min-width: 3500px)  {
  .zoom-indicator-wrapper {
   left: 1050px;
   top: 520px;
  }
}


@media only screen and (min-width: 4500px){
   .zoom-indicator-wrapper {
   left: 1650px;
   top: 720px;
  }
}