.App {
  text-align: center;
  position: relative;
}

body {
  background-color: var(--liteBlue);
}

:root {
  --liteBlue: #f1f3f5;
  --grey: #414c56;
  --white: #ffffff;
  --primaryBlue: #20242b;
  --secondary-text: #414c56;
  --purpleBlue: #4647fe;
  --black: #000000;
  --primary-color: #006098;
  --primary-blue: #4461f2;
  --faded-primary-blue: #edf0fe;
  --lite-border: #d9d9d9;
  --tertiary-white: #65727d;
  --error-red: #bf2e3a;
  --error-red-lite: #ead5d9;
  --modal-bg: rgba(0, 0, 0, 0.5);
  --disabled-white: #eeeeee;
  --disabled-loading-state: #e4e4e4;
  --disabled-btn-bg: #ffffff66;
  --disabled-btn-background: #cccccc;
  --disabled-btn-text: #666666;
  --line-color: #fafafa;
  --error-modal-bg: #f9f1e8;
  --warning-title-color: #c36e19;
  --modal-blue-bg: #f1f3f5;
  --tertiary-text: #65727d;
  --table-border: #e5e8eb;
  --table-badge-good: #2387491a;
  --table-badge-fair: #c26b131a;
  --table-badge-poor: #bf2e3a1a;
  --warning: #c26b13;
  --flu-green: #17e711;
  --flu-mustard: #fbaa05;

  --available: #238749;
  --available-bg: #2387491a;

  --hold: #ce8102;
  --hold-bg: #ce81021a;

  --manual: #3192d3;
  --manual-bg: #3192d31a;

  --scrap: #ed5564;
  --scrap-bg: #ed55641a;

  --shipped: #0e960c;
  --shipped-bg: #0e960c1a;

  --removed: #e80013;
  --removed-bg: #e800131a;

  --empsale: #f01a67;
  --empsale-bg: #f01a671a;

  --non-conform: #d78703;
  --non-conform-bg: #d787031a;

  --received: #009d0b;
  --received-bg: #009d0b1a;

  --quality: #0094a3;
  --quality-bg: #0094a31a;

  --destruction: #fa5935;
  --destruction-bg: #fa59351a;

  --consumed: #0d41f6;
  --consumed-bg: #0d41f61a;

  --error: #bf2e3a;
  --error-bg: #bf2e3a1a;

  --incubation: #9e00c7;
  --incubation-bg: #9e00c71a;

  --warning-mustard: #c26b13;
  --poor-red: #bf2e3a;

  --close-btn-active-bg: #4461f233;

  --error-alert-bg: #ffecec;
  --warning-alert-bg: #fff9e9;
  --success-alert-bg: #f7fefa;

  --success-alert-border: #019b63;
  --warning-alert-border: #fec022;
  --error-alert-border: #ff3c3c;

  --following-lgv-bg-inner: #354bef;
  --following-lgv-bg-outer: #95a2ff;

  --toggle-switch-bg: #4461f21f;

  --block-storage-width: 4.5px;
  --block-storage-height: 2.5px;

  --block-single-stack-storage-width: 4.5px;
  --block-single-stack-storage-height: 4.5px;

  --rack-storage-width: 4px;
  --rack-storage-height: 4px;

  --block-single-right-margin: 5;
}

.leaflet-div-icon {
  background-color: none !important;
  border: none !important;
  border-radius: 6px !important;
}

.loggin-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.leaflet-container {
  background-color: #ffffff !important;
}

.nav-icon {
  height: 70px !important;
  width: 70px !important;
  border-radius: 50px;
  z-index: 2000 !important;
}

/* .nav-icon p {
  opacity: 1;
} */

/**
Nav Heatmap Color codes
-----------------------
*/

.nav-red {
  background-color: #ff0000;
  color: #ff0000;
}

.nav-yellow {
  background-color: #ffff00;
  color: #ffff00;
}

.nav-green {
  background-color: #008000;
  color: #008000;
}

.nav-null {
  color: #ffffff10;
}

/**
-----------------------
*/

/*Objects Layer*/
.dock-door {
  z-index: 99 !important;
}

.pallets-wrapper {
  z-index: 10 !important;
}

/* :where(.css-dev-only-do-not-override-byeoj0).ant-table-wrapper table {
  
} */

:where(.css-dev-only-do-not-override-byeoj0).ant-table-wrapper .ant-table {
  background: none !important;
  font-family: "Poppins", sans-serif !important;
}

.ant-table {
  background: none !important;
}

.ant-table-cell {
  cursor: grab;
  background: none !important;
}

.ant-table-cell:hover {
  background: none !important;
}

.ant-table-cell:active,
.ant-table-cell:visited {
  cursor: move;
}

.ant-table-thead {
  display: none !important;
}

.active-shipment-status-container-on-drag {
  border: 0.5px dashed var(--primary-blue);
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.App.dashboard.yard-view-false .dash-items-section a[href*="yard-view"] {
  display: none;
}

.map-container-wrapper.plant-LAN .pallet.pallet-double,
.map-container-wrapper.plant-LAN .pallet.pallet-single {
  font-size: 1.8px !important;
}
