.alert-message-wrapper {
  width: 100%;
  position: fixed;
  z-index: 1008;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0px;
}

.alert-message-container {
  width: 500px;
  height: 65px;
  border-radius: 20px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  animation: alertAnimation 30s ease;
  transform: translate(0px, -100px);
  box-shadow: 0 6px 12px rgb(0 0 0 / 0.2);
}

.alert-message-icon-section {
  flex: 0.1;
}

.alert-message-message-section {
  flex: 0.8;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0px 20px;
}

.alert-message-title {
  font-weight: 500;
  font-size: 16px;
  color: var(--primaryBlue);
}

.alert-message-description {
  font-weight: 400;
  font-size: 14px;
  color: var(--tertiary-white);
}

.alert-message-close-section {
  flex: 0.1;
}

.alert-message-close-section img {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.success-message {
  background-color: var(--success-alert-bg);
  border: 1px solid var(--success-alert-border);
}

.warning-message {
  background-color: var(--warning-alert-bg);
  border: 1px solid var(--warning-alert-border);
}

.error-message {
  background-color: var(--error-alert-bg);
  border: 1px solid var(--error-alert-border);
}

@keyframes alertAnimation {
  0% {
    transform: translate(0px, -100px);
  }
  10% {
    transform: translate(0px, 0px);
  }
  90% {
    transform: translate(0px, 0px);
  }
  100% {
    transform: translate(0px, -100px);
  }
}
