.check-list {
  display: block;
  margin-right: 0;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  border-radius: 10px;
  pointer-events: all;
}

.select-all-btn {
  margin-top: 10px;
}

.ant-checkbox-group {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
}

.ant-checkbox-wrapper {
  width: 80px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
