.greetings-wrapper {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--white);
}

.designs {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
}

.bottom-trees-design {
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
}

.circle-design {
    width: 100%;
    height: 100%;
}

.circle-design #circle2ui{
    position: absolute;
    right: 0;
    width: 200px;
    height: 400px;
}

.circle-design #circle1ui {
    position: absolute;
    left: 0;
    width: 200px;
    height: 300px;
}

.greetings-container {
    width: 60%;
    height: 60%;
    border: none;
    border-radius: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: var(--liteBlue);
    box-shadow: 0 6px 12px rgb(0 0 0 / 0.2);
    z-index: 2;
}

.greetings-container .text {
    color: var(--secondary-text);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.greetings-container h1 {
    height: 10px;
    font-size: 40px;
}

.greetings-container h3 {
    font-size: 20px;
}

.greetings-container p {
    font-size: 10px;
}

.select-location-btn {
    background-color: var(--primary-blue);
    color: var(--white);
}

.select-location-btn img {
    padding-left:10px;
}

.loading-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width:320px) {
    .greetings-container {
        width: 90%;
    }

    .greetings-container img {
        width: 80%;
    }

    .greetings-container h3{
        font-size: 5vw;
    }

    .select-location-btn {
        width: 30vw;
    }
}