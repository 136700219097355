.time-machine-container {
  margin: 0px 30px 10px 30px;
  /* height: 600px; */
  height: 70vh;
  background-color: white;
  border-radius: 10px;
  user-select: none;
  position: relative;
  overflow: scroll;
}

#time-machine-map-id-reference {
  transform: scale(1);
}

.time-machine-container .indoor-map {
  height: 100%;
  border-radius: 10px;
  border: none;
}

.loader {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-color: var(--white);
  z-index: 1000;
  border-radius: 10px;
}

.lgv-marker {
  z-index: 1200 !important;
}

.popup-title {
  font-size: 20px;
  font-weight: 500;
}

/* @keyframes fade { 
    from { opacity: 0.5; } 
}

.blinking {
    animation: fade 1s infinite alternate;
} */

.spinner {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: radial-gradient(farthest-side, var(--primary-color) 94%, #0000)
      top/4px 4px no-repeat,
    conic-gradient(#0000 30%, var(--primary-color));
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 4px), #000 0);
  animation: s3 1s infinite linear;
}

@keyframes s3 {
  100% {
    transform: rotate(1turn);
  }
}



.time-machine-container::-webkit-scrollbar {
  display: none;
}
