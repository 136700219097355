.popup-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.modal-title-section {
  flex: 0.4;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-action-buttons {
  flex: 0.6;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.popup-title {
  font-size: 24px;
  font-weight: 500;
  color: var(--primaryBlue);
  width: 100%;
  text-align: left;
}

.lgv-follow-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 50px;
  border-radius: 30px;
  padding: 0px 35px 2px 30px;
  cursor: pointer;
  margin-right: 100px;
}

.following {
  background-color: var(--primary-blue);
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.un-following {
  background-color: var(--white);
}

.following-text {
  color: var(--white);
}

.un-following-text {
  color: var(--black);
}

.lgv-follow-button img {
  margin: 0px 10px;
}

.lgv-follow-button span {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

.lgv-popup-wrapper {
  width: 100%;
  height: 100%;
  background-color: var(--modal-bg);
  position: fixed;
  z-index: 1008;
  top: 0;
  left: 0;
  overflow: hidden;
  cursor: pointer;
  webkit-user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lgv-popup-container .dock-loader {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--white);
}

.spinner {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: radial-gradient(farthest-side, var(--primary-color) 94%, #0000)
      top/4px 4px no-repeat,
    conic-gradient(#0000 30%, var(--primary-color));
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 4px), #000 0);
  animation: s3 1s infinite linear;
}

.lgv-popup-container {
  width: 90vw !important;
  height: 90vh !important;
  margin-bottom: 10px;
  background-color: var(--liteBlue);
  padding: 20px;
  border-radius: 30px;
  overflow: scroll;
}

.modal-animation {
  animation: modalMinimizeAnimation 0.5s ease;
}

.lgv-follow-modal-animation {
  animation: modalFollowAnimation 0.5s ease;
}

.popup-container-details-section {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}

.lgv-meta-data-section-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 0.3;
  margin: 0px 15px 0px 0px;
}

.lgv-graphic-wrapper {
  height: 100%;
  margin: 15px 0px;
  display: flex;
  flex-direction: column;
  flex: 0.7;
  border-radius: 16px;
  background-color: var(--white);
}

.lgv-graphic-section {
  flex: 0.8;
  height: 100%;
  margin: 20px;
}

.lgv-graphic-switch-section {
  height: 90px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.lgv-graphic-image-section {
  width: 100%;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.lgv-graphic-image-section img {
  height: auto;
  width: 100%;
}

.lgv-rspls-alarm {
  left: 14%;
  top: 7%;
}

.lgv-fpls-alarm {
  left: 10%;
  top: 39%;
}

.lgv-nav-alarm {
  left: 10%;
  top: 68%;
}

.lgv-lpls-alarm {
  left: 15%;
  top: 94%;
}

.lgv-rvpls-alarm {
  left: 57.5%;
  top: 6%;
}

.lgv-lvpls-alarm {
  left: 56.8%;
  top: 94%;
}

.lgv-lms-alarm {
  left: 90%;
  top: 42%;
}

.lgv-rpls-alarm {
  left: 88%;
  top: 61%;
}

.lgv-fork-alarm {
  left: 92.5%;
  top: 95%;
}
.alarm-label {
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  background: #f1f3f5;
  border-radius: 10px;
  padding-right: 10px;
}
.alarm-label span {
  font-size: 12px;
  font-weight: 500;
}

.alarm-label,
.sensor-label {
  position: absolute;
  transform: translate(-50%, -50%);
}

.alarm-label img,
.sensor-label img {
  width: 45px;
  height: 40px;
}

.lgv-rspls-sensor {
  left: 13%;
  top: 7%;
}

.lgv-fpls-sensor {
  left: 6%;
  top: 38%;
}

.lgv-lpls-sensor {
  left: 13%;
  top: 93%;
}

.lgv-lvpls-sensor {
  left: 46.5%;
  top: 94.5%;
}

.lgv-rvpls-sensor {
  left: 46.5%;
  top: 7%;
}

.lgv-rpls-sensor {
  left: 81.4%;
  top: 61.4%;
}

.lgv-nav-sensor {
  left: 36%;
  top: 50%;
  cursor: pointer;
  height: 100px;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1111 !important;
}

.lgv-nav-sensor-text {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}

.lgv-nav-popup {
  position: absolute;
  top: 70px;
  display: none;
  width: 600px;
  padding: 10px;
  background-color: white;
  margin-top: 30px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  border-radius: 12px;
  flex-direction: row;
  flex-wrap: wrap;
}

.lgv-nav-sensor:hover .lgv-nav-popup {
  display: flex;
  transition: ease-in all 2s;
  z-index: 1015 !important;
}

.lgv-graphic-meta-data-section {
  flex: 0.2;
  height: 100%;
  background-color: var(--modal-blue-bg);
  margin: 20px;
  border-radius: 12px;
}

.alarm .lgv-graphic-meta-data {
  justify-content: space-between;
}
.seperator {
  width: 100%;
  height: 0.5px;
  background-color: var(--lite-border);
  margin: 10px 0px 30px 0px;
}

.lgv-graphic-meta-data {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  /* align-items: center; */
  justify-content: flex-start;
}

.lgv-meta-data {
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* justify-content: center; */
  /* height: 70px; */
  max-width: 400px;
  margin: 5px 20px;
}

.lgv-meta-data-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--secondary-text);
}
.lgv-meta-data-value {
  color: var(--primaryBlue);
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  margin-top: -10px;
}

.popup-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 20px;
  background-color: var(--white);
  margin: 15px 0px;
}

.popup-section-title {
  font-size: 20px;
  letter-spacing: 2px;
  font-weight: 500;
  letter-spacing: 2px;
  line-height: 20px;
  text-transform: uppercase;
  margin-bottom: 5px;
  color: var(--tertiary-text);
  padding: 20px 20px 0px 20px;
}

.details-section {
  /* max-height: 650px; */
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0px 20px 20px 20px;
}

.lgv-details {
  margin-right: 50px;
  margin-bottom: -20px;
  text-align: left;
  min-width: 125px;
}

.details-data-title {
  font-size: 14px;
  font-weight: 400;
  color: var(--secondary-text);
}

.details-data {
  font-weight: 500;
  font-size: 16px;
  color: var(--grey);
  margin-top: -10px;
}

.lgv-icon-wrapper {
  position: relative;
}

.lgv-icon-style {
  display: flex;
  width: 18px;
  height: 18px;
  border: 1px solid #ffffff;
  border-radius: 2px;
  font-size: 12px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins" !important;
  z-index: 1008 !important;
}

.lgv-wrapper-circle-inner {
  position: absolute;
  top: -15px;
  left: -15px;
  padding: 1px;
  background-color: var(--following-lgv-bg-inner);
  width: 50px !important;
  height: 50px !important;
  border-radius: 50px;
  z-index: -5;
  animation: lgvFollowAnimationInnerCircle 3s ease infinite;
}

@keyframes lgvFollowAnimationInnerCircle {
  0% {
    opacity: 1;
    transform: scale(0);
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
    transform: scale(0.7);
  }
}

.lgv-wrapper-circle-outer {
  position: absolute;
  top: -15px;
  left: -15px;
  padding: 1px;
  opacity: 0.5;
  background-color: var(--following-lgv-bg-outer);
  width: 50px !important;
  height: 50px !important;
  border-radius: 50px;
  z-index: -7;
  animation: lgvFollowAnimationOuterCircle 2s ease infinite;
  animation-delay: 0.2s;
}

@keyframes lgvFollowAnimationOuterCircle {
  0% {
    opacity: 1;
    transform: scale(0);
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
    transform: scale(1.2);
  }
}

.block-icon-style {
  display: flex;
  width: 4px;
  height: 4px;
  border: 1px solid #ffffff;
  border-radius: 2px;
  font-size: 4px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins" !important;
  z-index: 1008 !important;
}

.block-icon-style-sm {
  display: flex;
  width: 4px;
  height: 4px;
  border: 1px solid #ffffff;
  border-radius: 2px;
  font-size: 4px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins" !important;
  z-index: 1008 !important;
}

@keyframes modalMinimizeAnimation {
  0% {
    transform: translateX(0px) scale(1);
  }
  100% {
    transform: translate(-1200px, 300px) scale(0);
  }
}

@keyframes modalFollowAnimation {
  0% {
    transform: translateX(0px) scale(1);
  }
  100% {
    transform: translate(1200px, 300px) scale(0);
  }
}

@media only screen and (min-width: 1920px) {
  .lgv-rspls-alarm {
    left: 19%;
    top: 7%;
  }
  .lgv-fpls-alarm {
    left: 13%;
    top: 39%;
  }
  .lgv-nav-alarm {
    left: 12%;
    top: 68%;
  }
  .lgv-lpls-alarm {
    left: 19%;
    top: 94%;
  }
  .lgv-rvpls-alarm {
    left: 53.5%;
    top: 6%;
  }
  .lgv-lms-alarm {
    left: 87%;
    top: 42%;
  }
  .lgv-rpls-alarm {
    left: 84%;
    top: 61%;
  }
  .lgv-fork-alarm {
    left: 90.5%;
    top: 95%;
  }
  .lgv-lvpls-alarm {
    left: 52.8%;
    top: 94%;
  }
}

@media only screen and (max-width: 1880px) {
  .lgv-rspls-alarm {
    left: 19%;
    top: 7%;
  }
  .lgv-fpls-alarm {
    left: 13.5%;
    top: 39%;
  }
  .lgv-nav-alarm {
    left: 12%;
    top: 68%;
  }
  .lgv-lpls-alarm {
    left: 19%;
    top: 94%;
  }
  .lgv-rvpls-alarm {
    left: 52.5%;
    top: 6%;
  }
  .lgv-lms-alarm {
    left: 86%;
    top: 42%;
  }
  .lgv-rpls-alarm {
    left: 85%;
    top: 61%;
  }
  .lgv-fork-alarm {
    left: 89.5%;
    top: 95%;
  }
  .lgv-lvpls-alarm {
    left: 51.8%;
    top: 94%;
  }
}
@media only screen and (max-width: 2050px) {
}

@media only screen and (min-width: 2100px) and (max-width: 3000px) {
  .lgv-popup-container {
    width: 60vw !important;
    height: auto !important;
  }
}

@media only screen and (min-width: 3800px) {
  .lgv-popup-container {
    width: 45vw !important;
    height: auto !important;
  }
  .lgv-nav-sensor {
    left: 370px;
    top: 125px;
    cursor: pointer;
    height: 100px;
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1111 !important;
  }
}
@media only screen and (max-width: 1600px) {
  .sensor .lgv-meta-data {
    width: 180px;
  }
  .alarm .lgv-graphic-meta-data {
    word-break: break-word;
  }
}
/* Responsive annotations */
@media only screen and (max-width: 1500px) {
  .alarm-label span {
    padding: 3% 0%;
    font-size: 10px;
  }
  .alarm-label img,
  .sensor-label img {
    width: 30px;
    height: 25px;
  }
}

@media only screen and (max-width: 1100px) {
  .alarm-label span {
    font-size: 6px;
    padding: 0%;
  }
  .alarm-label img,
  .sensor-label img {
    width: 20px;
    height: 15px;
  }
}

@media only screen and (max-width: 800px) {
  .alarm-label span {
    font-size: 4px;
  }
  .alarm-label img
  {
    width: 15px;
    height: 10px;
  }
  .lgv-rpls-alarm {
    left: 86%;
    top: 60%;
  }
  .lgv-nav-sensor-text {
    font-size: 8px;
  }
}
