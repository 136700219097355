.time-machine-controller-container {
  width: 100%;
  bottom: -100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.time-machine-sections {
  z-index: 1007;
  width: 70%;
  height: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: var(--white);
  border: none;
  border-radius: 15px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  padding: 25px;
}

.time-machine-controller-top-section {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.time-machine-seek-bar {
  width: 100%;
}

.ant-slider {
  margin: 7px 5px !important;
}

input[type="range"] {
  accent-color: var(--primary-blue);
}

.time-machine-controller-bottom-section {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.control-buttons-section {
  display: flex;
  gap: 30px;
  flex-direction: row;
}

.control-buttons-section .time-machine-play-btn {
  cursor: pointer;
  /* margin: 0px 40px; */
  flex: 3.3;
}

.time-machine-fast-forward-btn {
  flex: 3.3;
  cursor: pointer;
}

.time-bar-section {
  color: var(--tertiary-white);
  font-weight: 400;
  font-size: 16px;
  font-family: monospace;
  letter-spacing: -0.7px;
}

.toggle-controller-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 70px;
}

.control-buttons-section img {
  width: 25px;
  height: 25px;
}

.toggle-controller-section h4 {
  font-size: 16px;
  font-weight: 500;
  color: var(--tertiary-white);
  margin-right: 10px;
}

.small-controller-toggle-container {
  width: 100%;
  height: 30px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
}

.small-controller-toggle-section {
  z-index: 1015;
  background-color: var(--white);
  border-radius: 15px 15px 0px 0px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  width: 20%;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.small-controller-toggle-section h4 {
  font-weight: 500;
  font-size: 16px;
  color: var(--tertiary-white);
  margin: 0px 10px;
}

.no-action-allowed {
  user-select: none;
  cursor: not-allowed;
  /* margin: 0px 40px; */
}

.new-ui .time-machine-controller-container {
  bottom: -130px !important;
}
