#block-location-table-block-rack-hof {
  width: 100%;
  max-height: 300px;
  border-collapse: collapse;
  overflow-y: scroll !important;
  margin-top: 13px;
}

#block-location-table-block-rack-hof td {
  border-top: 1px solid var(--table-border);
  border-bottom: 1px solid var(--table-border);
}

.block-table-header-block-rack {
  max-width: 110px;
  padding: 6px 10px;
  font-weight: 500;
  font-size: 14px;
  color: var(--primaryBlue);
}

.block-table-data-block-rack {
  text-wrap: wrap;
  max-height: 40px;
  padding: 6px 10px;
  font-weight: 400;
  font-size: 12px;
  color: var(--secondary-text);
}

.text-center {
  text-align: center;
}

.block-table-data-block {
  text-wrap: wrap;
  max-height: 10px;
  padding: 0px 10px;
  font-weight: 400;
  font-size: 12px;
  line-height: 4px;
  text-align: left;
  color: var(--secondary-text);
}

.align-center-block-rack {
  display: flex;
  align-items: center;
  justify-content: center;
}

.quality-block-rack {
  width: 116px;
  height: 36px;
  border-radius: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.quality-text-block-rack {
  font-weight: 400;
  font-size: 14px;
  text-align: center;
}

thead {
  background-color: var(--white);
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  line-height: 24px;
  border-bottom: 1px solid var(--table-border);
}

.details-block-rack {
  margin-right: 50px;
  margin-bottom: -20px;
  text-align: left;
  min-width: 150px;
}

.details-data-title-block-rack {
  font-size: 14px;
  font-weight: 400;
  color: var(--tertiary-text);
}

.details-data-block-rack {
  font-weight: 500;
  font-size: 16px;
  color: var(--grey);
  margin-top: -10px;
}

.lgv-icon-style-block-rack {
  display: flex;
  width: 18px;
  height: 18px;
  border: 1px solid #ffffff;
  border-radius: 2px;
  font-size: 12px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins" !important;
  z-index: 1008 !important;
}

.block-icon-style-block-rack {
  display: flex;
  width: 4px;
  height: 4px;
  border: 1px solid #ffffff;
  border-radius: 2px;
  font-size: 4px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins" !important;
  z-index: 1008 !important;
}

.block-icon-style-sm-block-rack {
  display: flex;
  width: 4px;
  height: 4px;
  border: 1px solid #ffffff;
  border-radius: 2px;
  font-size: 4px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins" !important;
  z-index: 1008 !important;
}

.table-loader {
  height: 100px;
  margin: 0px 30px 30px 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
  border-radius: 10px;
}

.table-spinner {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: radial-gradient(farthest-side, var(--primary-color) 94%, #0000)
      top/4px 4px no-repeat,
    conic-gradient(#0000 30%, var(--primary-color));
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 4px), #000 0);
  animation: s3 1s infinite linear;
}
