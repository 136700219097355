.site-selection-container {
    background-color: white;
    display: flex;
    flex-direction: row;
    height: 50px;
    width: 230px;
}

.site-left-section {
    display: flex;
    flex-direction: column;
    flex: .7;
    padding-top: 5px;
}

.site-right-section {
    flex: .3;
}

.site-selection-button {
    border-radius: 50px;
    width: 100px;
    height: 40px;
    border: none;
    margin-top: 6px;
    font-size: 16px;
    background-color: var(--disabled-white);
    color: var(--grey);
    cursor: default;
    font-family: 'Poppins', sans-serif !important;
}

.site-unselected-button {
    border-radius: 50px;
    width: 100px;
    height: 40px;
    border: none;
    margin-top: 6px;
    cursor: pointer;
    font-size: 16px;
    background-color: var(--primary-blue);
    color: var(--white);
    font-family: 'Poppins', sans-serif !important;
}

.location {
    font-size: 16px;
    font-family: 400;
    margin: 1px 5px !important;
    color: var(--primaryBlue);
    font-family: 'Poppins', sans-serif !important;
}

.location-state {
    font-size: 14px;
    font-weight: 400;
    margin: 1px 5px !important;
    color: var(--tertiary-white);
    font-family: 'Poppins', sans-serif !important;
}