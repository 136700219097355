.dashboard-container {
  position: relative;
}

.m-r-5 {
  margin-right: 5px;
}

.m-r-10 {
  margin-right: 10px;
}

.m-r-15 {
  margin-right: 15px;
}

.m-r-20 {
  margin-right: 20px;
}

.m-r-25 {
  margin-right: 25px;
}

#indoor-map-id-reference {
  .leaflet-overlay-pane {
    position: relative;
    z-index: auto;
  }

  .leaflet-marker-pane {
    z-index: auto;
  }

  .leaflet-pane svg {
    position: relative;
    z-index: 1000 !important;
  }

  .pallets-wrapper-row {
    z-index: 0 !important;
  }
}

@media only screen and (min-width: 2560px) {
  .flexible-screen {
    display: flex;
    flex-direction: row;
  }

  .flexible-left-screen {
    flex: 0.8;
  }

  .flexible-right-screen {
    flex: 0.2;
  }
}
