.nav-quality-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-quality-container {
  width: 98%;
  height: 80vh;
  margin-top: -10px;
}

.nav-heatmap-id-reference {
  height: 100%;
  width: 100%;
}

.loader {
  width: 98%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-color: var(--white);
  z-index: 1000;
  border-radius: 10px;
}

.level-loader {
  width: 300px;
  height: 80px;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 10px;
}

.spinner {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: radial-gradient(farthest-side, var(--primary-color) 94%, #0000)
      top/4px 4px no-repeat,
    conic-gradient(#0000 30%, var(--primary-color));
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 4px), #000 0);
  animation: s3 1s infinite linear;
}

@keyframes s3 {
  100% {
    transform: rotate(1turn);
  }
}

/* Zoom Controls*/

.zoom-control-center-nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 10px;
  top: 70px;
  z-index: 1111;
}

.zoom-controls-nav {
  width: 32px;
  height: 35px;
  background-color: white;
  font-size: 24px;
  border: none;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  cursor: pointer;
}

.zoom-reset-controls-nav {
  margin-top: 5px;
  border-radius: 10px;
}

.zoom-reset-controls-nav img {
  width: 17px;
  height: 17px;
}

.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  border: none !important;
  border-radius: 10px 10px 0px 0px !important;
}

.leaflet-control-zoom-in {
  width: 35px;
  height: 35px;
  border-radius: 10px 10px 0px 0px !important;
  border: none !important;
  background-color: white;
  font-size: 24px;
  box-shadow: 0 3px 10px rgb(0 0 0 /0.2);
  cursor: pointer;
}

.leaflet-control-zoom-out {
  width: 35px;
  height: 35px;
  border-radius: 0px 0px 10px 10px !important;
  border: none !important;
  background-color: white;
  font-size: 24px;
  box-shadow: 0 3px 10px rgb(0 0 0 /0.2);
  cursor: pointer;
}

.leaflet-control-zoom-in span,
.leaflet-control-zoom-out span {
  color: var(--primary-blue);
  font-weight: 200;
}

.disable-map-drag {
  pointer-events: none;
}

.custom-tooltip {
  border: 2px solid #53514c;
  border-radius: 15px;
  padding: 15px;
  font-size: 15px;
  font-weight: 500;
  color: #000000;
}

/* scaling up the container for larger screens*/

@media only screen and (min-width: 2500px) {
  .nav-quality-container {
    overflow: hidden;
    border-radius: 20px;
  }

  .leaflet-container {
    background-color: #53514c;
    transform: scale(1.5);
    transform-origin: center;
  }
  /* in-build zoom controllers */
  .leaflet-control-zoom {
    position: absolute;
    top: 170px;
    left: 450px;
  }
  /* reset btn */
  .zoom-control-center-nav {
    top: 250px;
    left: 460px;
  }
}

@media only screen and (min-width: 3500px) {
  .leaflet-container {
    transform: scale(2);
  }
  /* in-build zoom controllers */
  .leaflet-control-zoom {
    top: 500px;
    left: 1000px;
  }
  /* reset btn */
  .zoom-control-center-nav {
    top: 570px;
    left: 1010px;
  }
}

@media only screen and (min-width: 4500px) {
  .leaflet-container {
    transform: scale(2.5);
  }
  /* in-build zoom controllers */
  .leaflet-control-zoom {
    top: 700px;
    left: 1600px;
  }
  /* reset btn */
  .zoom-control-center-nav {
    top: 770px;
    left: 1610px;
  }
}
