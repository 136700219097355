.allocated-container {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.loader-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.allocated-rm-loader {
    display: flex;
    position: absolute;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.allocated-container .legend-modal {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    border-radius: 16px;
    padding: 10px;
    cursor: pointer;
    width: max-content;


}

.allocated-container .legend-modal-popup-container {
    position: absolute;
}


.allocatedview-legend-popup-wrapper {

    display: flex;
    justify-content: center;
    align-items: center;
}

#allocated-popup-wrapper-id {

    width: 100vw;

    .allocated-popup-container {

        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 1003;
        padding: 20px 20px 0 20px;

    }

    .legend-modal-btn {
        color: #414C56;
    }

    .allocated-top-section {
        display: flex;
        align-items: center;
        width: 100%;
        gap: 20px
    }

    .allocated-top-section h1 {
        color: #414C56;
    }

    .allocated-top-section img {
        cursor: pointer;
    }

    .dock-door-list {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        justify-content: center;
        margin-bottom: 10px;
    }

    .dock-door {
        display: flex;
        justify-content: center;
        width: 350px;
        height: 100px;
        border-radius: 12px;
        box-sizing: border-box;
        padding: 15px;
        align-items: center;
    }

    .empty-dock-door {
        background-color: #ABCDE3;
    }

    .container {
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: center;
        width: 100%;
        gap: 5%;
    }

    .container h1 {
        margin: 0;
    }

    .status {
        width: 2vw;
        height: 2vw;
        border-radius: 50%;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .dock-door-info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }




    /* For HD Device 1900 * 1080 */
    @media only screen and (max-width: 2000px) {
        .dock-door {
            width: 220px;
            height: 80px;
            padding: 10px;
        }

        .dock-door h1 {
            font-size: 30px;

        }

        .dock-door .status {
            width: 2vw;
            height: 2vw;
        }

        .status {
            width: 3vw;
            height: 3vw;
        }
    }

    /* for Laptop Screen */
    @media only screen and (max-width: 1300px) {
        .allocated-top-section h1 {
            font-size: 20px;
        }

        .allocated-top-section img {
            height: 25px;
        }

        .dock-door {
            width: 170px;
            height: 60px;
            margin: 0 5px;
        }

        .dock-door h1 {
            font-size: 15px;
        }

        .status {
            width: 3vw;
            height: 3vw;
        }
    }

    .rm-loader {
        background: transparent;
        position: absolute;
        top: 50%;
    }
}