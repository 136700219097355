.header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 30px;
  position: relative;
}

.title-section {
  display: flex;
  align-items: center;
  justify-content: center;
}

.title-section h3 {
  color: var(--grey);
  font-size: 24px;
}

.options-section {
  display: flex;
  align-items: center;
}

.time-options-section {
  display: flex;
  align-items: center;
  justify-content: center;
}

.reverse-content {
  display: flex;
  flex-direction: row-reverse;
}

.box-shadow {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.reverse-content-img {
  padding-left: 10px;
}

.customize-btn {
  height: 25px;
}

.time-machine-header-topic {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.time-machine-header-topic img {
  width: 30px;
  height: 30px;
  margin-right: 20px;
  cursor: pointer;
}

.time-frame-displayer {
  width: 400px;
  height: 40px;
  border-radius: 45px;
  background-color: var(--white);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.time-frame-displayer .from-date,
.to-date {
  color: var(--primary-blue);
  font-weight: 500;
  font-size: 16px;
  margin: 0px 10px;
}

@media only screen and (max-width: 780px) {
  .button-container {
    height: 10px;
    font-size: 10px !important;
  }
}

@media only screen and (max-width: 430px) {
  .header-container {
    margin-top: -15px;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .header-container .time-options-section {
    margin-top: -15px;
  }
}

@media only screen and (min-width: 2560px) {
  .header-container {
    width: 98%;
  }
  .warehouse-btn {
    display: none;
  }
}
