.yard-legend-popup-wrapper {
  display: flex;
  width: 100vw;
  height: 100vh;
  background-color: var(--modal-bg);
  z-index: 1008;
  position: fixed;
  top: 0;
  margin-left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.yard-color-pallet {
  height: 25px;
  background-color: var(--white);
  padding: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border: none;
  border-radius: 30px;
  margin: 10px;
}

.yard-legend-popup-container {
  min-height: 418px;
  width: 873px;
  border-radius: 25px;
  background-color: var(--liteBlue);
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1003;
  padding-bottom: 20px;
}

.yard-legends-top-section {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.yard-legends-top-section h4 {
  font-size: 20px;
  margin-left: 30px;
}

.yard-legends-top-section img {
  width: 35px;
  height: 35px;
  margin-right: 30px;
  cursor: pointer;
}

.yard-legends-bottom-section {
  width: 100%;
}

.yard-colors-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.yard-colors-top-section {
  width: 100%;
  height: 40px;
  margin-left: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.yard-colors-top-section h4 {
  color: var(--tertiary-white);
  font-size: 16px;
  font-family: Poppins;
  text-transform: uppercase;
}

.yard-colors-bottom-section {
  width: 100%;
  padding-left: 10px;
  margin-top: -10px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.color-pallet {
  height: 25px;
  background-color: var(--white);
  padding: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border: none;
  border-radius: 30px;
  margin: 10px;
}

.yard-legend-color {
  width: 30px;
  height: 30px;
  border: none;
  border-radius: 30px;
}

.yard-legend-name {
  font-size: 12px;
  color: var(--grey);
  margin: 0px 7px;
}

.prevent-scroll {
  overflow: hidden;
  width: 100%;
}

@media only screen and (max-width: 1024px) {
  .yard-legend-popup-container {
    width: 70%;
  }
}

@media only screen and (max-width: 430px) {
  .yard-legend-popup-container {
    width: 90%;
    overflow-y: scroll;
  }

  .yard-legends-top-section h4 {
    font-size: 15px;
    margin-left: 30px;
  }

  .yard-colors-top-section h4 {
    font-size: 12px;
  }

  .color-pallet {
    height: 15px;
    background-color: var(--white);
    padding: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border: none;
    border-radius: 30px;
    margin: 10px;
  }

  .yard-legend-color {
    width: 20px;
    height: 20px;
  }
}

@media only screen and (max-width: 380px) {
  .yard-legend-popup-container {
    width: 90%;
    overflow-y: scroll;
  }

  .yard-legends-top-section h4 {
    font-size: 12px;
    margin-left: 30px;
  }

  .yard-colors-top-section h4 {
    font-size: 10px;
  }

  .color-pallet {
    height: 10px;
    background-color: var(--white);
    padding: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border: none;
    border-radius: 30px;
    margin: 10px;
  }

  .yard-legend-color {
    width: 10px;
    height: 10px;
  }
}

@media only screen and (max-width: 320px) {
  .yard-legend-popup-container {
    width: 90%;
    overflow-y: scroll;
  }

  .yard-legends-top-section h4 {
    font-size: 12px;
    margin-left: 30px;
  }

  .yard-colors-top-section h4 {
    font-size: 10px;
  }

  .color-pallet {
    height: 10px;
    background-color: var(--white);
    padding: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border: none;
    border-radius: 30px;
    margin: 10px;
  }

  .yard-legend-color {
    width: 10px;
    height: 10px;
  }
}
