#yard-view-map-container-id .zoom-control-center {
  z-index: 9999;
}

#yard-view-map-container-id {
  height: 100vh;
}

@media only screen and (min-width: 2560px) {
  .App.yard-view .flexible-left-screen {
    flex: 1 1;
  }
  .App.yard-view .flexible-right-screen {
    flex: unset;
  }
}
