.popup-wrapper {
  width: 100%;
  height: 100%;
  background-color: var(--modal-bg);
  position: fixed;
  z-index: 1008;
  top: 0;
  left: 0;
  overflow: hidden;
  cursor: pointer;
  webkit-user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-container {
  width: 95vw;
  height: 90vh;
  background-color: var(--liteBlue);
  padding: 20px;
  border-radius: 20px;
  overflow: hidden;
  cursor: default;
  user-select: none;
  display: flex;
  flex-direction: column;
}

.popup-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 20px;
}

.popup-bottom {
  width: 100%;
  height: 100vh;
  display: flex;
  overflow: hidden;
  background-color: var(--white);
  border-radius: 20px;
}

.vertical-scroller {
  width: 100%;
  overflow: scroll !important;
}

.left-selector-section {
  display: flex;
  align-items: flex-start;
  overflow: auto;
  padding: 0 30px;
}

.storage-list-item {
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  text-align: left;
  font-weight: 400;
  font-size: 20px;
  margin: 20px 0;
  cursor: pointer;
  user-select: auto;
}

.storage-list-item p {
  margin: 0 0 0 10px;
}

.storage-list-item:link {
  width: 80px;
  background-color: var(--faded-primary-blue);
  color: var(--primary-color);
}

.unselected {
  background-color: var(--faded-primary-blue);
  color: var(--primary-color);
}

.selected {
  background-color: var(--primary-blue);
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
}

.right-display-section {
  cursor: auto;
  user-select: auto;
  overflow-y: scroll;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-title {
  font-size: 24px;
  font-weight: 500;
  color: var(--primaryBlue);
  text-align: left;
}

.popup-close {
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-container .dock-loader {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--modal-blue-bg);
}

.spinner {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: radial-gradient(farthest-side, var(--primary-color) 94%, #0000)
      top/4px 4px no-repeat,
    conic-gradient(#0000 30%, var(--primary-color));
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 4px), #000 0);
  animation: s3 1s infinite linear;
}

.non-lgv-table-loader {
  height: 100px;
  margin: 0px 30px 30px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
  border-radius: 10px;
}

.non-lgv-table-spinner {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: radial-gradient(farthest-side, var(--primary-color) 94%, #0000)
      top/4px 4px no-repeat,
    conic-gradient(#0000 30%, var(--primary-color));
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 4px), #000 0);
  animation: s3 1s infinite linear;
}
