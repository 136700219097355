.no-data-modal-wrapper {
    width: 100%;
    height: 100%;
    background-color: var(--modal-bg);
    position: fixed;
    z-index: 1008;
    top: 0;
    left: 0;
    overflow: hidden;
    cursor: pointer;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.no-data-modal-container {
    width: 60%;
    height: 40%;
    padding: 20px;
    background-color: var(--error-modal-bg);
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.no-data-modal-title {
    height: 50px;
    color: var(--warning-title-color);
}

.no-data-modal-sub-title {
    color: var(--black);
}

.no-data-modal-button-div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.no-data-modal-button {
    width: 70px;
    margin-top: 10px;
    background-color: var(--white);
    color: var(--primary-blue);
}

@media only screen and (max-width:425px) {
    .no-data-modal-container {
        height: 60%;
        padding: 20px;
    }
    .no-data-modal-title {
        font-size: 3vw;
    }
    .no-data-modal-sub-title {
        font-size: 3vw;
    }
}