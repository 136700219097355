@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600&display=swap");

body {
  margin: 0;
  font-family: "Poppins", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  height: 5px;
  width: 4px;
  transition: ease 1s all;
}

::-webkit-scrollbar-track {
  border-radius: 15px;
}

::-webkit-scrollbar-thumb {
  border-radius: 15px;
  background-color: darkgrey;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar:hover {
  width: 13px;
  transition: ease 1s all;
}
